import React from "react"
import styled from "styled-components"
import Button from "@components/ui/buttons/Button"
import CustomGatsbyImage from "@components/utils/CustomGatsbyImage"
import * as PropTypes from "prop-types"
import Heading from "@components/ui/Heading"
import Prose from "@components/ui/prose"

const ResultsAndStatsStyled = styled.section`
  padding-top: 70px;
  padding-bottom: 0;
  background: ${props =>
      `linear-gradient(180deg, ${props.gradientColour1} 0%, ${props.gradientColour2} 100%);`}
    .cta-wrap {
    margin: 80px auto 30px auto;
    text-align: center;

    @media screen and (min-width: 992px) {
      margin: 80px auto 0 auto;
    }
  }

  .results-and-stats {
    @media screen and (min-width: 992px) {
      margin: 80px 0 80px;
    }

    .stats {
      .stat-box {
        margin-bottom: 10px;
        padding: 0 5px;

        .stat-box-inner {
          display: flex;
          background-color: var(--color-bg);
          min-height: 170px;
          padding: 20px 30px;

          .img-wrapper {
            align-self: center;
          }

          .content-wrapper {
            @media screen and (min-width: 992px) {
              padding-left: 30px;
            }

            .stat-title {
              font-size: 40px;
              margin-bottom: 5px;
            }

            .stat-info {
              font-size: 18px;
            }
          }
        }
      }
    }

    .description {
      padding-left: 50px;
      @media screen and (max-width: 992px) {
        padding: 20px;
      }

      .title,
      .main-body-text,
      .supporting-text {
        color: var(--color-text-light);
      }
    }

    &.vertical {
      .stats {
        .stat-box {
          .stat-box-inner {
            min-height: 0;
            border-radius: 5px;

            .content-wrapper {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              text-align: center;

              @media screen and (min-width: 992px) {
                text-align: left;
                flex-wrap: nowrap;
              }

              .stat-title {
                margin-top: 5px;
                width: 100%;

                @media screen and (max-width: 500px) {
                  font-size: 25px;
                }

                @media screen and (min-width: 992px) {
                  width: auto;
                  padding-right: 20px;
                }
              }

              .stat-info {
                align-self: center;
              }
            }
          }
        }
      }
    }
  }

  .results-and-stats-image {
    margin-bottom: 80px;
  }
`

export const ResultsAndStats = ({
  title,
  content,
  supportingContent,
  textColor,
  gradientColour1,
  gradientColour2,
  stats,
  image,
}) => {
  const textColorStyle = { color: textColor }
  return (
    <ResultsAndStatsStyled
      className="container-fluid full-width-sections"
      gradientColour1={gradientColour1}
      gradientColour2={gradientColour2}
    >
      <div className="row">
        <section className="results-and-stats vertical">
          <div className="container">
            <div className="row">
              <div className="col-md-8 stats">
                {stats.map(({ text, figure }, key) => {
                  return (
                    <div className="col-md-12 stat-box" key={key}>
                      <div className="stat-box-inner">
                        <div className="content-wrapper purple">
                          <Heading
                            node="h3"
                            variant="h4"
                            className="stat-title"
                          >
                            <span style={textColorStyle}>{figure}</span>
                          </Heading>
                          <p
                            className="stat-info"
                            dangerouslySetInnerHTML={{ __html: text }}
                            style={textColorStyle}
                          />
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>

              <div className="col-md-4 description">
                {title && (
                  <Prose>
                    <Heading node="h2" color="white" underlined>
                      {title}
                    </Heading>
                  </Prose>
                )}
                <div
                  className="main-body-text"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
                <div
                  className="supporting-text"
                  dangerouslySetInnerHTML={{ __html: supportingContent }}
                />
              </div>
            </div>
            <div className="cta-wrap">
              <Button color="white" to="/contact/">
                Let's work together
              </Button>
            </div>
          </div>
        </section>

        {image && (
          <section className="results-and-stats-image">
            <div className="container">
              <CustomGatsbyImage {...image.localFile} />
            </div>
          </section>
        )}
      </div>
    </ResultsAndStatsStyled>
  )
}

ResultsAndStats.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  supportingContent: PropTypes.string,
  textColor: PropTypes.string,
  gradientColour1: PropTypes.string,
  gradientColour2: PropTypes.string,
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      figure: PropTypes.string,
    })
  ).isRequired,
  image: PropTypes.object,
}

ResultsAndStats.defaultProps = {
  title: `Results & Stats`,
}
