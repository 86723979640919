import React from "react"
import Helmet from "react-helmet"
import { graphql } from "gatsby"
import classnames from "classnames"
import styled from "styled-components"

import Seo from "@components/Seo"
import { CaseStudyLegacyHero } from "@components/sections/heros/CaseStudyLegacyHero"
import {
  getCaseStudyEdges,
  useAllCaseStudies,
} from "@components/utils/useAllCaseStudies"
import { WhatWeDid } from "@components/sections/WhatWeDid"
import { ResultsAndStats } from "@components/sections/case-study/ResultsAndStats"
import CustomGatsbyImage from "@components/utils/CustomGatsbyImage"
import { ClientQuote } from "@components/sections/case-study/ClientQuote"
import { CaseStudyArrows } from "@components/sections/case-study/CaseStudyArrows"
import { CoverageHighlights } from "@components/sections/case-study/CoverageHighlights"
import Heading from "@components/ui/Heading"

const PageWrapper = styled.div`
  padding-bottom: 0px;
  padding-top: 70px;

  @media screen and (max-width: 992px) {
    padding-top: 50px;
  }
`

const AboutTheProjectStyled = styled.section`
  text-align: center;

  .main-body-text {
    font-size: 24px;
    line-height: 33px;

    @media screen and (min-width: 1200px) {
      padding: 0 150px;
    }
  }
`

const Images = styled.section`
  @media screen and (min-width: 992px) {
    padding: 100px 0;
  }
  @media screen and (max-width: 991px) {
    padding: 50px 0;
  }

  .image {
    @media screen and (max-width: 991px) {
      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
    }

    img {
      width: 100%;
    }
  }
`

export default function CaseStudyLegacyPage({ data }) {
  const page = data.allWpCaseStudy.nodes[0]
  const { next, previous } = getCaseStudyEdges(
    useAllCaseStudies(),
    page.databaseId
  )

  const {
    coverageHighlightsImage,
    aboutTheProject,
    imageColumns,
    featuredColour,
    featuredColour2,
    whatWeDidImage,
    whatWeDid,
    whatWeDidFurtherInfo,
    resultsAndStatsImage,
    resultsAndStatsSupportingText,
    resultsAndStatsText,
    resultsAndStatsVertical,
    gradientColour1,
    gradientColour2,
    clientQuote,
  } = page.acfCaseStudyV2

  return (
    <>
      <Seo {...page.seo} />
      <Helmet bodyAttributes={{ class: "full-hero" }} />

      <CaseStudyLegacyHero
        nextCaseStudy={next}
        previousCaseStudy={previous}
        projectName={page.title}
        taxServices={page.taxServices?.nodes}
        heroTitle={page.acfCaseStudyV2?.subheader}
        featuredColour={page.acfCaseStudyV2?.featuredColour}
        gatsbyImageData={
          page.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
        }
      />

      <div id="portfolio-body">
        <PageWrapper>
          <div className="container">
            <div className="row">
              <AboutTheProjectStyled className="about-the-project container">
                <Heading node="h2" style={{ margin: "0 auto 40px auto" }}>
                  About the project
                </Heading>
                {aboutTheProject && (
                  <div
                    className="main-body-text"
                    dangerouslySetInnerHTML={{ __html: aboutTheProject }}
                  />
                )}
              </AboutTheProjectStyled>

              {imageColumns?.length && (
                <Images className="images">
                  <div className="container">
                    <div className="row">
                      {imageColumns[0].images?.map(({ image }, key) => {
                        const length = imageColumns[0].images.length
                        return (
                          <div
                            key={key}
                            className={classnames(
                              "image",
                              `col-md-${12 / length}`
                            )}
                          >
                            <CustomGatsbyImage {...image.localFile} />
                          </div>
                        )
                      })}
                      {imageColumns[0]?.image && (
                        <div className={classnames("image", `col-md-12`)}>
                          <CustomGatsbyImage
                            {...imageColumns[0].image.localFile}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Images>
              )}

              {whatWeDid && (
                <WhatWeDid
                  featuredColour={featuredColour2}
                  image={whatWeDidImage}
                  furtherInfo={whatWeDidFurtherInfo}
                  overviewText={whatWeDid}
                />
              )}
            </div>
          </div>

          {coverageHighlightsImage && (
            <CoverageHighlights image={coverageHighlightsImage} />
          )}

          {resultsAndStatsVertical && (
            <ResultsAndStats
              content={resultsAndStatsText}
              stats={resultsAndStatsVertical}
              supportingContent={resultsAndStatsSupportingText}
              textColor={featuredColour2}
              gradientColour1={gradientColour1}
              gradientColour2={gradientColour2}
              image={resultsAndStatsImage}
            />
          )}

          {clientQuote && (
            <ClientQuote quote={clientQuote} backgroundColor={featuredColour} />
          )}

          <CaseStudyArrows nextCaseStudy={next} previousCaseStudy={previous} />
        </PageWrapper>
      </div>
    </>
  )
}

export const query = graphql`
  fragment caseStudyDataLegacy on WpCaseStudy {
    taxServices {
      nodes {
        slug
        name
      }
    }
    featuredImage {
      node {
        localFile {
          extension
          publicURL
          childImageSharp {
            gatsbyImageData(
              quality: 85
              transformOptions: { cropFocus: CENTER }
              layout: FULL_WIDTH
            )
          }
        }
      }
    }
    acfCaseStudyV2 {
      clientQuote
      aboutTheProject
      coverageHighlightsImage {
        localFile {
          extension
          publicURL
          childImageSharp {
            gatsbyImageData(
              transformOptions: { cropFocus: CENTER }
              layout: FULL_WIDTH
            )
          }
        }
      }
      featuredColour
      featuredColour2
      gradientColour1
      gradientColour2
      imageColumns {
        ... on WpCaseStudy_Acfcasestudyv2_ImageColumns_1Image {
          fieldGroupName
          image {
            localFile {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(
                  quality: 85
                  transformOptions: { cropFocus: CENTER }
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
        ... on WpCaseStudy_Acfcasestudyv2_ImageColumns_3Images {
          fieldGroupName
          images {
            image {
              localFile {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    quality: 85
                    transformOptions: { cropFocus: CENTER }
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
        ... on WpCaseStudy_Acfcasestudyv2_ImageColumns_2Images {
          fieldGroupName
          images {
            image {
              localFile {
                extension
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    quality: 85
                    transformOptions: { cropFocus: CENTER }
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
      }
      overlayColour
      resultsAndStatsImage {
        localFile {
          extension
          publicURL
          childImageSharp {
            gatsbyImageData(
              quality: 85
              transformOptions: { cropFocus: CENTER }
              layout: FULL_WIDTH
            )
          }
        }
      }
      resultsAndStatsSupportingText
      resultsAndStatsText
      resultsAndStatsVertical {
        text
        figure
      }
      subheader
      whatWeDid
      whatWeDidFurtherInfo
      whatWeDidImage {
        localFile {
          extension
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 500
              quality: 85
              transformOptions: { cropFocus: CENTER }
              layout: CONSTRAINED
            )
          }
        }
      }
    }
  }

  query($slug: String!) {
    allWpCaseStudy(
      filter: {
        template: { templateName: { eq: "Digital Marketing V2" } }
        slug: { eq: $slug }
      }
    ) {
      nodes {
        databaseId
        id
        uri
        title
        link
        seo {
          canonical
          cornerstone
          focuskw
          metaDesc
          metaKeywords
          metaRobotsNofollow
          metaRobotsNoindex
          opengraphAuthor
          opengraphDescription
          opengraphModifiedTime
          opengraphImage {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, layout: FIXED)
              }
            }
          }
          opengraphPublishedTime
          opengraphPublisher
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
          title
          twitterDescription
          twitterTitle
          schema {
            raw
          }
        }
        ...caseStudyDataLegacy
      }
    }
  }
`
