import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import CustomGatsbyImage from "@components/utils/CustomGatsbyImage"
import Heading from "@components/ui/Heading"

const CoverageHighlightsStyled = styled.section`
  padding: 80px 0 30px;

  .image {
    @media screen and (min-width: 992px) {
      padding: 0 150px;
    }
  }
`

export const CoverageHighlights = ({ image }) => {
  return (
    <CoverageHighlightsStyled>
      <Heading node="h2">Coverage Highlights</Heading>
      <CustomGatsbyImage className="image" {...image?.localFile} />
    </CoverageHighlightsStyled>
  )
}

CoverageHighlights.propTypes = {
  image: PropTypes.object.isRequired,
}
