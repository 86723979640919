import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const ClientQuoteStyled = styled.section`
  text-align: center;
  padding: 40px 0;

  .container {
    p {
      color: var(--color-text-light);
      font-size: 24px;
    }
  }
`

export const ClientQuote = ({ backgroundColor, quote }) => {
  return (
    <ClientQuoteStyled
      className="client-quote container-fluid"
      style={{ backgroundColor }}
    >
      <div className="container" dangerouslySetInnerHTML={{ __html: quote }} />
    </ClientQuoteStyled>
  )
}

ClientQuote.propTypes = {
  quote: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
}

ClientQuote.defaultProps = {
  backgroundColor: `var(--color-primary)`,
}
