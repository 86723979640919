import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import { motion } from "framer-motion"
import { useToggle } from "@components/utils/UseToggle"
import Heading from "@components/ui/Heading"

const WhatWeDidStyled = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-top: 29px;

  @media screen and (min-width: 992px) {
    margin-bottom: 60px;
  }

  .info {
    align-self: center;

    .overview {
      margin-bottom: 20px;
    }

    .toggle-further-info {
      margin-top: 20px;
      cursor: pointer;

      &:hover,
      &:focus {
        text-decoration: none;
      }

      .arrow {
        padding-left: 15px;
        font-size: 15px;
      }
    }
  }

  .image {
    align-self: center;
    text-align: center;
    @media screen and (max-width: 992px) {
      padding-top: 20px;
      display: none;
    }
  }

  .further-info {
    padding-top: 60px;
    font-size: 17px;
    overflow: hidden;
  }
`

const variants = {
  closed: {
    height: 0,
    opacity: 0,
    ease: "easeOut",
    duration: 0.2,
    type: "tween",
  },
  open: {
    height: "auto",
    opacity: 1,
    transition: {
      duration: 0.25,
      type: "tween",
      ease: "easeIn",
    },
  },
}

export const WhatWeDid = ({
  sectionTitle,
  overviewText,
  featuredColour,
  image,
  furtherInfo,
}) => {
  const [isActive, isActiveActions] = useToggle(false)

  return (
    <WhatWeDidStyled className="what-we-did container">
      <div className="col-md-6 info">
        <Heading node="h2">{sectionTitle}</Heading>
        <div
          className="overview main-body-text brown"
          dangerouslySetInnerHTML={{ __html: overviewText }}
        />
        {furtherInfo && (
          <div
            className="toggle-further-info purple"
            onClick={isActiveActions.toggle}
            onKeyDown={isActiveActions.toggle}
            tabIndex={0}
            role="button"
            style={{ color: featuredColour, fontWeight: "bold" }}
          >
            {!isActive ? (
              <>
                Read More{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="chevron-down"
                  className="svg-inline--fa fa-chevron-down fa-w-14"
                  role="img"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
                  />
                </svg>
              </>
            ) : (
              <>
                Read Less{" "}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="chevron-up"
                  className="svg-inline--fa fa-chevron-up fa-w-14"
                  role="img"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M240.971 130.524l194.343 194.343c9.373 9.373 9.373 24.569 0 33.941l-22.667 22.667c-9.357 9.357-24.522 9.375-33.901.04L224 227.495 69.255 381.516c-9.379 9.335-24.544 9.317-33.901-.04l-22.667-22.667c-9.373-9.373-9.373-24.569 0-33.941L207.03 130.525c9.372-9.373 24.568-9.373 33.941-.001z"
                  />
                </svg>
              </>
            )}
          </div>
        )}
      </div>

      {image && (
        <div className="col-md-6 image">
          <GatsbyImage
            image={image?.localFile?.childImageSharp?.gatsbyImageData}
          />
        </div>
      )}

      {furtherInfo && (
        <motion.div
          animate={isActive ? "open" : "closed"}
          variants={variants}
          className="col-md-12 further-info"
          dangerouslySetInnerHTML={{ __html: furtherInfo }}
        />
      )}
    </WhatWeDidStyled>
  )
}

WhatWeDid.propTypes = {
  sectionTitle: PropTypes.string,
  overviewText: PropTypes.string,
  featuredColour: PropTypes.string,
  image: PropTypes.object,
  furtherInfo: PropTypes.string,
}

WhatWeDid.defaultProps = {
  sectionTitle: `What we did`,
}
